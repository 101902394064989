import './App.css';
import './myStyles.css';
import glasses from './Purple VR.png';
import twittericon from './twitter-32.png';
import instagramicon from './instagram-6-32.png';
import discordicon from './discord-2-32.png';


function App() {
  return (
      <div className="container">
        <h1 className = 'primary'>Meta Citizens</h1>
        <button className= 'twitter'><img src={twittericon} alt = "Twitter logo" onClick={() => window.open("https://twitter.com/meta_citizens")}/></button>
        <button className='instagram'><img src={instagramicon} alt = "Instagram logo" onClick={() => window.open("https://www.instagram.com/meta_citizens/")}/></button>
        <button className='discord'><img src={discordicon} alt = "Discord logo" onClick={() => window.open("https://discord.gg/nWdH55su7A")}/></button>
        <img className = 'vr' src = {glasses} alt = "VR Glasses" height = "1000" width= "1000"></img>
        <p className = 'secondary'>In development...</p>
      </div>
 
    );
    }

export default App;
